<style scoped>
    #topbar-button-container {
        display: flex;
    }

    #topbar-button-container :deep(button:first-child) {
        margin-right: 20px;
    }

    :deep(.credit-column) {
        display: block !important;
    }

    .modal-menu-title {
        text-align: center;
        color: rgb(50, 60, 70);
        font-size: 13px;
        margin: 30px 0px 30px 0px;
    }

    :deep(.radiobutton-container) {
        flex: unset;
    }

    #modal-supplier-invoice table {
        text-align: left;
        border-collapse: collapse;
        width: calc(100% - 40px);
        margin: 0 20px 40px 20px;
    }

    #modal-supplier-invoice table thead {
        background-color: rgb(240, 243, 246);
        color: rgb(100, 110, 120);
        font-size: 11px;
        text-transform: uppercase;
    }

    #modal-supplier-invoice table thead th {
        font-weight: 500;
        padding: 13px 20px;
        white-space: nowrap;
    }

    #modal-supplier-invoice table tbody tr {
        border-bottom: 1px solid rgb(230, 235, 240);
    }

    #modal-supplier-invoice table tbody td {
        padding: 15px 20px;
        font-weight: 500;
        font-size: 13px;
        color: rgb(30, 40, 50);
    }

    .form-inner-column {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        flex-wrap: nowrap;
    }

    .modal-actions-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 0;
        margin: 0 10px;
    }
    .modal-actions-wrapper button {
        margin-left: 0;
        margin-right: 0;
    }
    .action-amount-diff {
        text-align: center;
        padding: 15px 35px;
        margin: 5px 0;
        border-radius: 5px;
        background-color: rgba(255, 0, 0, 0.15);
        color: red;
        font-weight: bold;
    }
    .action-amount-diff table {
        width: 100% !important;
        margin: 0 !important;
    }
    .action-amount-diff tr:last-child {
        border-top: 1px solid red;
    }
    .action-amount-diff tr td:first-child {
        text-align: left;
    }
    .action-amount-diff tr td:last-child {
        text-align: right;
    }
</style>

<template>
    <CustomTopbar>
        <template v-slot:custom-topbar>
            <div id="topbar-button-container">
                <BaseButton buttonText="Créer une facture" @click="displayCreateSupplierInvoiceModal">>
                    <template v-slot:iconBefore>
                        <font-awesome-icon :icon="['fas', 'plus']" />
                    </template>
                </BaseButton>
                <BaseButton buttonText="Export Quadra" @click="displayExportSupplierInvoiceModal">
                    <template v-slot:iconBefore>
                        <font-awesome-icon :icon="['fas', 'file-export']" />
                    </template>
                </BaseButton>
            </div>
        </template>
    </CustomTopbar>
    <Aggrid
        apiRoute='supplierInvoice/list'
        :apiParams="filters"
        :columnDefs="columnDefs"
        :frameworkComponents="frameworkComponents"
        :cellRendererFunctions="cellRendererFunctions"
        :valueFormatterFunctions="valueFormatterFunctions"
        :context="context"
        aggridHeightSubstract="100px"
        @rowClicked="((event) => displayConsultSupplierInvoiceModal(event))"
    >
        <template v-slot:filter>
            <div>
                <BaseSelect
                    v-model="monthSearch"
                    label="Mois"
                    name="month"
                    :defaultOptions="months"
                    @onChange="onFiltersChange($event)"
                    :allowEmpty="true"
                    :maxHeight="300"
                />
            </div>
            <div>
                <BaseSelect
                    v-model="yearSearch"
                    label="Année"
                    name="year"
                    :defaultOptions="years"
                    @onChange="onFiltersChange($event)"
                    :allowEmpty="true"
                    :maxHeight="300"
                />
            </div>
            <div>
                <BaseSelect
                    v-model="supplierSearch"
                    label="Fournisseur"
                    name="supplierId"
                    fieldValue="id"
                    fieldLabel="name"
                    @onChange="onFiltersChange($event)"
                    :searchable="true"
                    api="supplier/search"
                    :minChars="1"
                    :maxHeight="300"
                />
            </div>
        </template>
    </Aggrid>
    <Modal id="modal-supplier-invoice" v-show="displayModalCreateOrConsultSupplierInvoice === true" :fullscreen="true" mode="menu">
        <template v-slot:modalMenu>
            <ModalMenu ref="supplierInvoiceModalMenu" :menuItems="menuItems" :startTab="startSupplierInvoiceModalTab" @onExit="closeModalCreateOrConsultSupplierInvoice" v-on:update:startTab="startSupplierInvoiceModalTab = $event">
                <template v-slot:modalMenuHead>
                    <div class="modal-menu-title">
                        <span v-show="createOrConsultMode === 'create'">Créer une facture fournisseur</span>
                        <span v-show="createOrConsultMode === 'consult'">Consulter une facture fournis.</span>
                    </div>
                </template>

                <template v-slot:modalTabs>
                    <div data-tab-name="general">
                        <form class="form-column">
                            <div>
                                <RadioButtonContainer label="Type de facture">
                                    <BaseRadioButton v-model="supplierInvoice.invoiceType" label="Facture" name="invoiceType" value="invoice" :disabled="createOrConsultMode === 'consult'"></BaseRadioButton>
                                    <BaseRadioButton v-model="supplierInvoice.invoiceType" label="Avoir" name="invoiceType" value="credit" :disabled="createOrConsultMode === 'consult'"></BaseRadioButton>
                                </RadioButtonContainer>
                                <BaseSelect
                                    v-if="supplierInvoice.invoiceType === 'invoice'"
                                    v-model="supplierInvoice.period"
                                    label="Période"
                                    :defaultOptions="supplierInvoice.periods"
                                    name="period"
                                    @onChange="onFormCreateOrConsultSupplierInvoiceInputChange($event, 1)"
                                    :disabled="createOrConsultMode === 'consult'"
                                    :maxHeight="300"
                                />
                                <BaseSelect
                                    v-model="supplierInvoice.supplier"
                                    label="Fournisseur"
                                    api="supplier/search"
                                    :apiParams="{
                                        period: supplierInvoice.period?.value,
                                        periodMandatory: supplierInvoice.invoiceType === 'invoice'
                                    }"
                                    fieldValue="id"
                                    fieldLabel="name"
                                    placeholder="Rechercher"
                                    :searchable="true"
                                    name="supplier"
                                    :error="formErrorsModalCreateOrConsultSupplierInvoice.step1?.supplier?.error?.message"
                                    @onChange="onFormCreateOrConsultSupplierInvoiceInputChange($event, 1)"
                                    :required="true"
                                    :displayError="displayErrorModalCreateOrConsultSupplierInvoice"
                                    :minChars="1"
                                    :disabled="createOrConsultMode === 'consult' || !supplierInvoice.period || !supplierInvoice.period?.value"
                                    :maxHeight="300"
                                />
                                <BaseInput
                                    v-model="form.invoiceNumberSequenceCurrent"
                                    label="Numéro de pièce"
                                    type="text"
                                    :disabled="true"
                                />
                                <BaseInput
                                    v-model="supplierInvoice.invoiceNumber"
                                    label="Numéro de facture"
                                    type="text"
                                    name="invoiceNumber"
                                    validator="text"
                                    :error="formErrorsModalCreateOrConsultSupplierInvoice.step1?.invoiceNumber?.error?.message"
                                    @onChange="onFormCreateOrConsultSupplierInvoiceInputChange($event, 1)"
                                    :displayError="displayErrorModalCreateOrConsultSupplierInvoice"
                                    :required="true"
                                    :disabled="createOrConsultMode === 'consult'"
                                />
                                <BaseDatepicker
                                    v-model="supplierInvoice.date"
                                    label="Date"
                                    name="date"
                                    :error="formErrorsModalCreateOrConsultSupplierInvoice.step1?.date?.error?.message"
                                    @onChange="onFormCreateOrConsultSupplierInvoiceInputChange($event, 1)"
                                    :required="true"
                                    :displayError="displayErrorModalCreateOrConsultSupplierInvoice"
                                    :disabled="createOrConsultMode === 'consult'"
                                />
                                <BaseDatepicker
                                    v-model="supplierInvoice.dueDate"
                                    label="Date d'échéance"
                                    name="dueDate"
                                    :error="formErrorsModalCreateOrConsultSupplierInvoice.step1?.dueDate?.error?.message"
                                    @onChange="onFormCreateOrConsultSupplierInvoiceInputChange($event, 1)"
                                    :required="true"
                                    :displayError="displayErrorModalCreateOrConsultSupplierInvoice"
                                    :disabled="createOrConsultMode === 'consult'"
                                />
                                <BaseInput
                                    v-if="supplierInvoice.invoiceType === 'invoice'"
                                    v-model="supplierInvoice.invoiceSum"
                                    label="Montant TTC"
                                    name="invoiceSum"
                                    type="text"
                                    validator="price"
                                    :required="supplierInvoice.invoiceType === 'invoice'"
                                    :error="formErrorsModalCreateOrConsultSupplierInvoice.step1?.invoiceSum?.error?.message"
                                    @onChange="onFormCreateOrConsultSupplierInvoiceInputChange($event, 1)"
                                    :displayError="displayErrorModalCreateOrConsultSupplierInvoice"
                                />
                            </div>
                            <div style="width: 80%" v-if="supplierInvoice.invoiceType === 'invoice'">
                                <BaseSelect
                                    v-if="createOrConsultMode !== 'consult'"
                                    v-model="form.receptionCurrent"
                                    label="Ajouter une réception"
                                    :defaultOptions="supplierInvoice.allReceptions"
                                    name="receptions"
                                    @onChange="onReceptionAdd"
                                    :maxHeight="300"
                                    :disabled="!supplierInvoice.supplier || !supplierInvoice.supplier?.value"
                                />
                                <div class="form-inner-column">
                                    <ListWithDelete
                                        v-model="supplierInvoice.availableReceptions" :label="`Liste des réceptions disponibles${currentSupplier != null ? ' ('+currentSupplier.name+')' : ''}`" @onDelete="onReceptionListDeleted" :disabled="true" v-if="createOrConsultMode !== 'consult'">
                                        <template v-slot:content="{ item }">
                                            <div>{{ item.label }}</div>
                                        </template>
                                    </ListWithDelete>
                                    <ListWithDelete
                                        v-model="supplierInvoice.receptions" :label="`Liste des réceptions${createOrConsultMode !== 'consult' ? ' séléctionnées' : ''}`" @onDelete="onReceptionListDeleted" :disabled="createOrConsultMode === 'consult'">
                                        <template v-slot:content="{ item }">
                                            <div>{{ item.label }}</div>
                                        </template>
                                    </ListWithDelete>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div data-tab-name="detail">
                        <form>
                            <div v-if="supplierInvoice.invoiceType === 'invoice'">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Article</th>
                                            <th>Affaire</th>
                                            <th>Réception</th>
                                            <th>Quantité</th>
                                            <th>Compte d'achat</th>
                                            <th>Établissement</th>
                                            <th>Prix unitaire HT (€)</th>
                                            <th>Total HT (€)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(article, index) in articlesFromReceptions" :key="index">
                                            <td>{{ article.label }}</td>
                                            <td>{{ article.affairNumber }}</td>
                                            <td>{{ article.receptionNumber }}</td>
                                            <td>{{ article.quantity }}</td>
                                            <td>
                                                <BaseInput
                                                    v-if="createOrConsultMode !== 'consult'"
                                                    v-model="article.buyAccount"
                                                ></BaseInput>
                                                <div v-else>
                                                    {{ article.buyAccount }}
                                                </div>
                                            </td>
                                            <td>
                                                <BaseSelect
                                                    v-model="article.establishment"
                                                    :defaultOptions="establishments"
                                                    :name="'establishment_' + index"
                                                    :error="formErrorsModalCreateOrConsultSupplierInvoice.step2?.['establishment_' + index]?.error?.message"
                                                    @onChange="onFormCreateOrConsultSupplierInvoiceInputChange($event, 2)"
                                                    :displayError="displayErrorModalCreateOrConsultSupplierInvoice"
                                                    :required="true"
                                                    :isSmall="true"
                                                    :disabled="createOrConsultMode === 'consult'"
                                                />
                                            </td>
                                            <td>
                                                <BaseInput
                                                    v-model="article.buyPrice"
                                                    type="text"
                                                    :name="'buyPrice_' + index"
                                                    validator="price"
                                                    :error="formErrorsModalCreateOrConsultSupplierInvoice.step2?.['buyPrice_' + index]?.error?.message"
                                                    @onChange="onFormCreateOrConsultSupplierInvoiceInputChange($event, 2)"
                                                    :displayError="displayErrorModalCreateOrConsultSupplierInvoice"
                                                    :required="true"
                                                    :disabled="createOrConsultMode === 'consult'"
                                                />
                                            </td>
                                            <td>{{ formatPrice(article.quantity * parseFloat(article.buyPrice)) }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Montant HT (€)</th>
                                            <th>Frais de port</th>
                                            <th>Frais d'emballage</th>
                                            <th>TVA (€)</th>
                                            <th>Montant TTC (€)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td v-if="supplierInvoice.invoiceType === 'invoice'">{{ formatPrice(articlesFromReceptions.reduce((partialSum, article) => partialSum + (article.quantity * parseFloat(article.buyPrice)), 0)) }}</td>
                                            <td v-if="supplierInvoice.invoiceType === 'credit'">
                                                <BaseInput
                                                    v-model="supplierInvoice.amount"
                                                    type="text"
                                                    name="amount"
                                                    validator="price"
                                                    :error="formErrorsModalCreateOrConsultSupplierInvoice.step2?.amount?.error?.message"
                                                    @onChange="onFormCreateOrConsultSupplierInvoiceInputChange($event, 2)"
                                                    :displayError="displayErrorModalCreateOrConsultSupplierInvoice"
                                                    :required="true"
                                                    :disabled="createOrConsultMode === 'consult'"
                                                />
                                            </td>
                                            <td v-if="!fees || !fees.shippingCosts">--</td>
                                            <td v-else>{{ formatPrice(fees.shippingCosts) }}</td>
                                            <td v-if="!fees || !fees.packagingCosts">--</td>
                                            <td v-else>{{ formatPrice(fees.packagingCosts) }}</td>
                                            <td>
                                                <BaseInput
                                                    v-model="supplierInvoice.tva"
                                                    type="text"
                                                    name="tva"
                                                    validator="price"
                                                    :error="formErrorsModalCreateOrConsultSupplierInvoice.step2?.tva?.error?.message"
                                                    @onChange="onFormCreateOrConsultSupplierInvoiceInputChange($event, 2)"
                                                    :displayError="displayErrorModalCreateOrConsultSupplierInvoice"
                                                    :required="true"
                                                    :disabled="createOrConsultMode === 'consult'"
                                                />
                                            </td>
                                            <td v-if="supplierInvoice.invoiceType === 'invoice'">{{ formatPrice(parseFloat(supplierInvoice.tva) + articlesFromReceptions.reduce((partialSum, article) => partialSum + (article.quantity * parseFloat(article.buyPrice)), 0) + fees?.shippingCosts + fees?.packagingCosts) }}</td>
                                            <td v-if="supplierInvoice.invoiceType === 'credit'">{{ formatPrice(parseFloat(supplierInvoice.tva) + parseFloat(supplierInvoice.amount)) }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </form>
                    </div>
                </template>
                <template v-slot:modalMenuFooter>
                    <div class="modal-actions-wrapper">
                        <div class="action-amount-diff" v-if="submitSupplierInvoiceDisabled">
                            <table>
                                <tr>
                                    <td>Détail</td>
                                    <td>{{ calculatedTtcAmount }}</td>
                                </tr>
                                <tr>
                                    <td>Réel</td>
                                    <td>{{ supplierInvoice.invoiceSum }}</td>
                                </tr>
                                <tr>
                                    <td/>
                                    <td>{{ ttcAmountDiff > 0 ? '+' : '-' }}{{ formatPrice(ttcAmountDiff) }}</td>
                                </tr>
                            </table>
                        </div>
                        <BaseButton class="orange-button" @click="submitModalCreateOrConsultSupplierInvoice" buttonText="Enregistrer" v-if="createOrConsultMode !== 'consult'" :disabled="submitSupplierInvoiceDisabled">
                            <template v-slot:iconBefore>
                                <font-awesome-icon :icon="['fas', 'save']" />
                            </template>
                        </BaseButton>
                    </div>
                </template>
            </ModalMenu>
        </template>
    </Modal>
    <Modal v-show="displayModalExportSupplierInvoice">
        <template v-slot:modalIcon>
            <font-awesome-icon :icon="['fas', 'plus']" />
        </template>
        <template v-slot:modalTitle>
            GÉNÉRER UN EXPORT QUADRA
        </template>
        <template v-slot:modalContent>
            <form>
                <div>
                    <BaseDatepicker
                        label="Mois"
                        @change="updateQuadraExportDate"
                        :onlyMonth="true"
                    >
                    </BaseDatepicker>
                </div>
                <div>
                    <BaseSelect
                        v-model="form.supplierInvoiceCurrent"
                        label="Facture fournisseur"
                        api="supplierInvoice/search"
                        :apiParams="{
                            onlyNotExported: form.exportQuadraOnlyNotExportedFilter
                        }"
                        fieldValue="id"
                        fieldLabel="label"
                        placeholder="Rechercher"
                        :searchable="true"
                        :minChars="1"
                        @onChange="onSupplierInvoiceAdd"
                    />
                </div>
                <div>
                    <ListWithDelete v-model="exportQuadra.supplierInvoices" label="Liste des factures fournisseur">
                        <template v-slot:content="{ item }">
                            <div>{{ item.label }}</div>
                        </template>
                    </ListWithDelete>
                </div>
            </form>
        </template>
        <template v-slot:modalButtons>
            <BaseButton class="white-button" @click="closeModalExportSupplierInvoice" buttonText="Fermer">
                <template v-slot:iconBefore>
                    <font-awesome-icon :icon="['fas', 'times']" />
                </template>
            </BaseButton>
            <BaseButton class="orange-button" @click="submitModalExportSupplierInvoice" buttonText="Valider">
                <template v-slot:iconBefore>
                    <font-awesome-icon :icon="['fas', 'check']" />
                </template>
            </BaseButton>
        </template>
    </Modal>
    <Dialog ref="dialog" />
</template>

<script>
    import { parse, format, addDays } from "date-fns"
    import CustomTopbar from '../../components/Topbar/CustomTopbar'
    import BaseButton from '../../components/Base/BaseButton'
    import BaseInput from '../../components/Base/BaseInput'
    import BaseCheckbox from '../../components/Base/BaseCheckbox'
    import BaseSelect from '../../components/Base/BaseSelect'
    import BaseDatepicker from '../../components/Base/BaseDatepicker'
    import RadioButtonContainer from '../../components/App/RadioButtonContainer.vue'
    import BaseRadioButton from '../../components/Base/BaseRadioButton.vue'
    import ListWithDelete from '../../components/App/ListWithDelete'
    import Aggrid from '../../components/Aggrid/Aggrid'
    import ListSupplierInvoicesActionButtonsRenderer from './ListSupplierInvoicesActionButtonsRenderer'
    import Modal from '../../components/App/Modal'
    import ModalMenu from '../../components/App/ModalMenu'
    import Dialog from '../../components/App/Dialog'
    import ListTextWithIconRenderer from '../../components/Aggrid/ListTextWithIconRenderer'
    import axios from 'axios'
    import { formatPrice } from '../../utils/formatter'
    import changeTypeEnum from '../../enums/changeTypeEnum'
    import { createToast } from 'mosha-vue-toastify';
    import lastDayOfMonth from 'date-fns/esm/lastDayOfMonth/index.js'

    export default {
        name: 'Suppliers',
        components: {
            CustomTopbar,
            BaseRadioButton,
            BaseButton,
            BaseInput,
            BaseCheckbox,
            BaseSelect,
            BaseDatepicker,
            RadioButtonContainer,
            ListWithDelete,
            Aggrid,
            Modal,
            ModalMenu,
            Dialog,
            ListTextWithIconRenderer
        },
        data() {
            return {
                columnDefs: [
                    { field: 'invoiceNumber', headerName : `Num. facture`, flex: 1, lockPosition: true, sortable: true },
                    { field: 'invoiceNumberSequence', headerName : `Num. pièce`, flex: 1, lockPosition: true, sortable: true },
                    { field: 'type', headerName : `Type facture`, flex: 1, lockPosition: true, cellRendererFunctionName: 'BillTypeRendererFunction', suppressMenu: true },
                    { field: 'date', headerName : `Date`, flex: 1, lockPosition: true, filter: 'agDateColumnFilter', sortable: true },
                    { field: 'dueDate', headerName : `Date d'échéance`, flex: 1, lockPosition: true, filter: 'agDateColumnFilter', sortable: true },
                    { field: 'supplier', headerName : `Fournisseur`, flex: 1, lockPosition: true, customFilter: 'supplier.name' },
                    { headerName : `Montant HT`, flex: 1, lockPosition: true, valueFormatterFunctionName: 'AmountValueFormatterFunction', filter: 'agNumberColumnFilter', customFilter: 'amount' },
                    { headerName : `TVA`, flex: 1, lockPosition: true, valueFormatterFunctionName: 'TvaValueFormatterFunction', filter: 'agNumberColumnFilter', customFilter: 'tva' },
                    {
                        headerName: 'Montant TTC',
                        flex: 1, lockPosition: true,
                        cellRendererFunctionName: 'TvaRendererFunction',
                        suppressMenu: true
                    },
                    { headerName : 'Exporté', flex: 1, cellRenderer: 'ListTextWithIconRenderer', cellRendererParams: {
                        field: 'exported',
                        mode: 'boolean'
                    },
                    filterParams: {
                        filterOptions: ['equals']
                    }},
                    { headerName : '', width: 220, cellRenderer: 'ListSupplierInvoicesActionButtonsRenderer', suppressMenu: true }
                ],
                frameworkComponents: {
                    ListSupplierInvoicesActionButtonsRenderer: ListSupplierInvoicesActionButtonsRenderer,
                    ListTextWithIconRenderer: ListTextWithIconRenderer
                },
                cellRendererFunctions: new Map([
                    [
                        'BillTypeRendererFunction',
                        function(param) {
                            return `
                                ${param?.data?.type === 'invoice' ? `Facture` : 'Avoir'}
                            `;
                        }
                    ],[
                        'TvaRendererFunction',
                        function(param) {
                            return formatPrice(param?.data?.tva + param?.data?.amount + param.data?.fees?.shippingCosts + param.data?.fees?.packagingCosts);
                        }
                    ]
                ]),
                valueFormatterFunctions: new Map([
                    [
                        'AmountValueFormatterFunction',
                        function(params){
                            return formatPrice(params?.data?.amount);
                        }
                    ],[
                        'TvaValueFormatterFunction',
                        function(params){
                            return formatPrice(params?.data?.tva);
                        }
                    ]
                ]),
                context: null,
                createOrConsultMode: null,
                displayModalCreateOrConsultSupplierInvoice: false,
                displayModalExportSupplierInvoice: false,
                supplierInvoice: null,
                exportQuadra: null,
                displayErrorModalCreateOrConsultSupplierInvoice: false,
                formErrorsModalCreateOrConsultSupplierInvoice: {
                    step1: {},
                    step2: {}
                },
                form: {
                    receptionCurrent: null,
                    supplierInvoiceCurrent: null,
                    invoiceNumberSequenceCurrent: null
                },
                monthSearch: null,
                yearSearch: null,
                supplierSearch: null,
                currentSupplier: null,
                filters: {
                    supplierId: null
                },
                months: [
                    { label: 'Janvier', value: 1 },
                    { label: 'Février', value: 2 },
                    { label: 'Mars', value: 3 },
                    { label: 'Avril', value: 4 },
                    { label: 'Mai', value: 5 },
                    { label: 'Juin', value: 6 },
                    { label: 'Juillet', value: 7 },
                    { label: 'Août', value: 8 },
                    { label: 'Septembre', value: 9 },
                    { label: 'Octobre', value: 10 },
                    { label: 'Novembre', value: 11 },
                    { label: 'Décembre', value: 12 },
                ],
                years: [],
                menuItems: this.getMenuItems(),
                startSupplierInvoiceModalTab: "general",
                articlesFromReceptions: [],
                fees: null,
                formatPrice: formatPrice,
                establishments: [
                    { label: 'EBC', value: 'ebc' },
                    { label: 'SMG', value: 'smg' }
                ],
                quadraStartDate: null,
                quadraEndDate: null,
                submitSupplierInvoiceDisabled: false,
                ttcAmountDiff: null,
                calculatedTtcAmount: null
            }
        },
        watch: {
            articlesFromReceptions: {
                handler(articlesFromReceptions) {
                    this.compareTTCAmount();
                },
                deep: true,
            }
        },
        beforeMount() {
            this.context = {
                componentParent: this
            };

            this.resetSupplierInvoice();
            this.resetExportSupplierInvoice();

            let years = [];
            for (let year = new Date().getFullYear(); year >= 2019; year--) {
                years.push({
                    label: year,
                    value: year
                });
            }
            this.years = years;
        },
        methods: {
            displayCreateSupplierInvoiceModal() {
                this.menuItems = this.getMenuItems();
                this.createOrConsultMode = 'create';
                this.resetSupplierInvoice();

                axios
                .get(`supplierInvoice/getInvoiceNumberSequence`, {
                    toastError: true,
                    showPreloader: true
                })
                .then((result) => {
                    this.form.invoiceNumberSequenceCurrent = result.data;

                    axios
                    .get(`supplierInvoice/getSupplierInvoiceDate`, {
                        showPreloader: true
                    })
                    .then(response => {
                        let periodLabel = `${new Date().getMonth() + 1}/${new Date().getFullYear()}`;
                        let periodValue = `01/${periodLabel}`;

                        this.supplierInvoice.periods = response.data;

                        if (this.supplierInvoice.periods.findIndex(period => period.value === periodValue) !== -1) {
                            this.supplierInvoice.period = {
                                label: periodLabel,
                                value: periodValue
                            };
                        }

                        this.displayModalCreateOrConsultSupplierInvoice = true;
                    })
                    .catch(() => {});
                })
                .catch((error) => {});
            },
            displayConsultSupplierInvoiceModal(supplierInvoice) {
                this.menuItems = this.getMenuItems();
                this.createOrConsultMode = 'consult';

                axios
                .get(`supplierInvoice/${supplierInvoice.id}`, {
                    toastError: true,
                    showPreloader: true
                })
                .then((result) => {
                    this.resetSupplierInvoice();

                    let supplierInvoice = result.data;

                    let period = parse(supplierInvoice.date, 'yyyy-MM-dd', new Date());

                    this.supplierInvoice = {
                        date: parse(supplierInvoice.date, 'yyyy-MM-dd', new Date()),
                        dueDate: parse(supplierInvoice.dueDate, 'yyyy-MM-dd', new Date()),
                        period: {
                            value: format(period, 'dd/MM/yyyy'),
                            label: format(period, 'MM/yyyy')
                        },
                        supplier: {
                            value: supplierInvoice.supplier.id,
                            label: supplierInvoice.supplier.name
                        },
                        availableReceptions: supplierInvoice.receptions.map(reception => {
                            return {
                                value: reception.id,
                                label: this.formateReceptionName(reception)
                            };
                        }),
                        receptions: supplierInvoice.receptions.map(reception => {
                            return {
                                value: reception.id,
                                label: this.formateReceptionName(reception)
                            };
                        }),
                        invoiceNumber: supplierInvoice.invoiceNumber,
                        invoiceType: supplierInvoice.type,
                        amount: formatPrice(supplierInvoice.amount),
                        invoiceSum: formatPrice(supplierInvoice.amount),
                        tva: formatPrice(supplierInvoice.tva),
                    };

                    this.form.invoiceNumberSequenceCurrent = supplierInvoice.invoiceNumberSequence;

                    this.articlesFromReceptions = supplierInvoice.articles.map(articleFromReception => {
                        return {
                            ...articleFromReception,
                            establishment: this.establishments.find(establishment => establishment.value === articleFromReception.establishment)
                        }
                    });
                    this.fees = supplierInvoice.fees;

                    this.displayModalCreateOrConsultSupplierInvoice = true;
                })
                .catch(() => {});
            },
            resetSupplierInvoice() {
                this.supplierInvoice = {
                    periods: [],
                    supplier: null,
                    receptions: [],
                    availableReceptions: [],
                    allReceptions: [],
                    invoiceNumber: null,
                    invoiceType: 'invoice',
                    date: new Date(),
                    dueDate: null,
                    amount: 0,
                    tva: 0,
                };
                this.currentSupplier = null;

                this.articlesFromReceptions = [];
                this.fees = null;
            },
            closeModalCreateOrConsultSupplierInvoice() {
                this.resetSupplierInvoice();
                this.startSupplierInvoiceModalTab = "general";
                this.displayModalCreateOrConsultSupplierInvoice = false;
                this.displayErrorModalCreateOrConsultSupplierInvoice = false;
            },
            onFormCreateOrConsultSupplierInvoiceInputChange(input, stepIndex) {
                let step = `step${stepIndex}`;

                if (input.error?.message !== null) {
                    this.formErrorsModalCreateOrConsultSupplierInvoice[step][input.name] = input;
                } else {
                    delete this.formErrorsModalCreateOrConsultSupplierInvoice[step][input.name];
                }

                if (input.changeType === changeTypeEnum.USER) {
                    if (input.name === 'period') {
                        this.currentSupplier = null;
                        this.supplierInvoice.supplier = null;
                        this.supplierInvoice.allReceptions = [];
                        this.supplierInvoice.receptions = [];
                        this.supplierInvoice.availableReceptions = [];
                        this.supplierInvoice.dueDate = null;
                        this.articlesFromReceptions = [];
                        this.calculateDueDate();
                        this.calculateTva();
                    } else if (input.name === 'supplier') {
                        this.supplierInvoice.allReceptions = [];
                        this.supplierInvoice.receptions = [];
                        this.supplierInvoice.availableReceptions = [];
                        this.articlesFromReceptions = [];

                        if (this.supplierInvoice.supplier === null) {
                            this.supplierInvoice.dueDate = null;
                            this.calculateDueDate();
                            this.calculateTva();
                        } else {
                            axios
                            .get(`supplier/${this.supplierInvoice.supplier.value}`, {
                                toastError: true,
                                showPreloader: true
                            })
                            .then((result) => {
                                this.currentSupplier = result.data;

                                this.calculateDueDate();

                                axios
                                .get(`reception/search?supplierId=${this.supplierInvoice.supplier.value}&withoutSupplierInvoice=true`, {
                                    showPreloader: true
                                })
                                .then(response => {
                                    let receptions = [];

                                    for (let reception of response.data) {
                                        receptions.push({
                                            value: reception.id,
                                            label: this.formateReceptionName(reception)
                                        });
                                    }

                                    this.supplierInvoice.allReceptions = receptions;
                                    this.supplierInvoice.availableReceptions = receptions;
                                    this.getArticlesFromReceptions();
                                })
                                .catch(() => {});
                            })
                            .catch(() => {});
                        }
                    } else if (input.name === 'date') {
                        this.calculateDueDate();
                    } else if (input.name.substr(0, 9) === 'buyPrice_') {
                        this.calculateTva();
                    } else if ((input.name === 'invoiceSum') || (input.name === 'tva')) {
                        this.compareTTCAmount();
                    }
                }

                if (this.displayErrorModalCreateOrConsultSupplierInvoice) {
                    this.menuItems = this.getMenuItems();
                }
            },
            onReceptionAdd(reception) {
                this.$nextTick(() => {
                    this.form.receptionCurrent = null;
                });

                // On ajoute la réception si elle n'est pas déjà présente
                if (reception.value != null && this.supplierInvoice.receptions.filter(element => element.value === reception.value.value).length === 0) {
                    this.supplierInvoice.receptions.push({
                        value: reception.value.value,
                        label: reception.value.label
                    });
                }

                this.getArticlesFromReceptions();
                this.calculateTva();
            },
            formateReceptionName(reception) {
                let label = `R${reception.id} [${formatPrice(reception.amount)}] - ${reception.date}`;
                let orders = '';
                for (let priceRequest of reception.priceRequests) {
                    if (orders.length > 0) orders += ', ';
                    orders += priceRequest.orderNumber;
                }
                label += ` (${orders})`;
                return label;
            },
            submitModalCreateOrConsultSupplierInvoice() {
                if (this.submitSupplierInvoiceDisabled) return;
                this.createSupplierInvoice();
            },
            formateSupplierInvoice(supplierInvoice) {
                return {
                    period: supplierInvoice.period?.value,
                    invoiceType: supplierInvoice.invoiceType,
                    invoiceNumber: supplierInvoice.invoiceNumber,
                    amount: parseFloat(supplierInvoice.amount),
                    tva: parseFloat(supplierInvoice.tva),
                    date: supplierInvoice.date,
                    dueDate: supplierInvoice.dueDate,
                    supplierId: supplierInvoice.supplier.value,
                    receptionIds: supplierInvoice.invoiceType === 'invoice' ? supplierInvoice.receptions.map(reception => reception.value) : [],
                    articles: this.articlesFromReceptions.map(articleFromReception => {
                        return {
                            receptionToPriceRequestToArticleId: articleFromReception.receptionToPriceRequestToArticleId,
                            supplierInvoiceBuyPrice: parseFloat(articleFromReception.buyPrice),
                            establishment: articleFromReception.establishment.value,
                            quantity: articleFromReception.quantity,
                            buyAccount: articleFromReception.buyAccount
                        };
                    }),
                };
            },
            createSupplierInvoice() {
                if (!this.checkFormCreateOrConsultSupplierInvoice()) return;

                axios
                .post('supplierInvoice/create', this.formateSupplierInvoice(this.supplierInvoice), {
                    toastSuccessMessage: `Facture fournisseur créée`,
                    toastError: true,
                    showPreloader: true
                })
                .then(() => {
                    this.closeModalCreateOrConsultSupplierInvoice();
                    this.emitter.emit('ag-grid-reload');
                })
                .catch(() => {});
            },
            checkFormCreateOrConsultSupplierInvoice() {
                this.displayErrorModalCreateOrConsultSupplierInvoice = true;
                this.menuItems = this.getMenuItems();

                if (this.supplierInvoice.invoiceType === 'invoice' && this.supplierInvoice.receptions.length === 0) {
                    createToast(`Merci d'ajouter au moins une réception`, {
                        position: 'bottom-right',
                        type: 'danger',
                        hideProgressBar: true,
                        showIcon: true,
                        transition: 'slide'
                    });

                    return false;
                }


                let formIsOk = true;
                for (let key of Object.keys(this.formErrorsModalCreateOrConsultSupplierInvoice)) {
                    if (this.formErrorsModalCreateOrConsultSupplierInvoice[key] != null && Object.keys(this.formErrorsModalCreateOrConsultSupplierInvoice[key]).length > 0) {
                        formIsOk = false;
                        break;
                    }
                }

                return formIsOk;
            },
            calculateTva() {
                if (this.createOrConsultMode === 'consult') return;

                let amount = this.articlesFromReceptions.reduce((partialSum, article) => partialSum + (article.quantity * parseFloat(article.buyPrice)), 0);

                let tva = 0;

                // On regarde si une TVA 20% doit être appliquée
                if (this.currentSupplier?.taxCode === 'tax_1') {
                    if (this.fees)
                        tva = (amount + (this.fees.shippingCosts + this.fees.packagingCosts)) * 0.2;
                    else
                        tva = amount * 0.2
                }

                this.supplierInvoice.tva = formatPrice(tva);
                this.supplierInvoice.amount = formatPrice(amount);
            },
            calculateDueDate() {
                if (this.createOrConsultMode === 'consult') return;

                if (this.currentSupplier === null) {
                    this.supplierInvoice.dueDate = null;
                    return;
                }

                switch (this.currentSupplier.deadline) {
                    case 'endOfMonth1':
                        if (!this.currentSupplier.dueDay) break;
                        this.supplierInvoice.dueDate = addDays(lastDayOfMonth(parse(this.supplierInvoice.date, 'dd/MM/yyyy', new Date())), this.currentSupplier.dueDay);
                        break;
                    case 'endOfMonth2':
                        if (!this.currentSupplier.dueDay) break;
                        this.supplierInvoice.dueDate = lastDayOfMonth(addDays(parse(this.supplierInvoice.date, 'dd/MM/yyyy', new Date()), this.currentSupplier.dueDay));
                        break;
                    case 'endOfMonth3':
                        if (!this.currentSupplier.dueDay) break;
                        this.supplierInvoice.dueDate = addDays(lastDayOfMonth(addDays(parse(this.supplierInvoice.date, 'dd/MM/yyyy', new Date()), this.currentSupplier.dueDay)), 15);
                        break;
                    case 'daysNumber':
                        if (!this.currentSupplier.dueDay) break;
                        this.supplierInvoice.dueDate = addDays(parse(this.supplierInvoice.date, 'dd/MM/yyyy', new Date()), this.currentSupplier.dueDay);
                        break;
                    case 'cash':
                    case 'beforeRemoval':
                        this.supplierInvoice.dueDate = this.supplierInvoice.date;
                        break;
                }
            },
            resetExportSupplierInvoice() {
                this.quadraStartDate = null;
                this.quadraEndDate = null;
                this.exportQuadra = {
                    supplierInvoices: []
                }
            },
            displayExportSupplierInvoiceModal() {
                axios
                    .get('supplierInvoice/search?onlyNotExported=true', {
                        toastError: true,
                        showPreloader: true
                    })
                    .then((result) => {
                        this.resetExportSupplierInvoice();
                        this.exportQuadra.supplierInvoices = [];
                        for (let supplierInvoice of result.data) {
                            this.exportQuadra.supplierInvoices.push({
                                value: supplierInvoice.id,
                                label: supplierInvoice.label
                            });
                        }
                        this.displayModalExportSupplierInvoice = true;
                    })
                    .catch(() => {});
            },
            onSupplierInvoiceAdd(supplierInvoice) {
                this.$nextTick(() => {
                    this.form.supplierInvoiceCurrent = null;
                });

                // On ajoute la facture fournisseur si elle n'est pas déjà présente
                if (supplierInvoice.value != null && this.exportQuadra.supplierInvoices.filter(element => element.value === supplierInvoice.value.value).length === 0) {
                    this.exportQuadra.supplierInvoices.push({
                        value: supplierInvoice.value.value,
                        label: supplierInvoice.value.label
                    });
                }
            },
            closeModalExportSupplierInvoice() {
                this.resetExportSupplierInvoice();
                this.displayModalExportSupplierInvoice = false;
            },
            submitModalExportSupplierInvoice() {
                let supplierInvoiceIds = this.exportQuadra.supplierInvoices.map(supplierInvoice => supplierInvoice.value);
                if (supplierInvoiceIds.length > 0) {
                    this.getExportSupplierInvoice(this.exportQuadra.supplierInvoices.map(supplierInvoice => supplierInvoice.value));
                } else {
                    createToast(`L'export doit contenir au moins une facture`, {
                        position: 'bottom-right',
                        type: 'danger',
                        hideProgressBar: true,
                        showIcon: true,
                        transition: 'slide'
                    });
                }
            },
            getExportSupplierInvoice(supplierInvoiceIds) {
                axios
                .get(`supplierInvoice/export?supplierInvoiceIds=${supplierInvoiceIds.toString()}`, {
                    toastError: true,
                    showPreloader: true,
                    responseType: 'blob'
                })
                .then((result) => {
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(new Blob([result.data]));
                    link.setAttribute('download', `export_factures_fournisseur_${format(new Date(), 'ddMMyyyy_HHmmss')}.txt`);
                    document.body.appendChild(link);
                    link.click();

                    this.emitter.emit('ag-grid-reload');
                    this.closeModalExportSupplierInvoice();
                })
                .catch(() => {});
            },
            onFiltersChange(input) {
                if (input.changeType === changeTypeEnum.USER) {
                    this.filters[input.name] = input?.value?.value || null;
                }
            },
            getMenuItems() {
                let menuItems = [
                    { name: "general", label: "Général", step: 1 },
                    { name: "detail", label: "Détail", step: 2 }
                ];

                return menuItems.map(menuItem => {
                    let menuItemFormatted = {
                        name: menuItem.name,
                        label: menuItem.label
                    };

                    if (this.displayErrorModalCreateOrConsultSupplierInvoice) {
                        let step = `step${menuItem.step}`
                        if (this.formErrorsModalCreateOrConsultSupplierInvoice[step] == null || Object.keys(this.formErrorsModalCreateOrConsultSupplierInvoice[step]).length === 0) {
                            menuItemFormatted.checked = true;
                        } else {
                            menuItemFormatted.unchecked = true;
                        }
                    }

                    return menuItemFormatted;
                });
            },
            getArticlesFromReceptions() {
                let receptionIds = this.supplierInvoice.receptions.map(reception => reception.value);

                this.articlesFromReceptions = [];

                if (receptionIds.length > 0) {
                    axios
                    .get(`supplierInvoice/articlesFromReceptions?receptionIds=${receptionIds.toString()}`, {
                        toastError: true,
                        showPreloader: true
                    })
                    .then((result) => {
                        this.articlesFromReceptions = result.data.articles.map(articleFromReception => {
                            (this.currentSupplier.taxSystem ? articleFromReception.buyAccount = articleFromReception.buyAccount.replace("RR", this.currentSupplier.taxSystem) : '')
                            return {
                                ...articleFromReception,
                                establishment: this.establishments.find(establishment => establishment.value === articleFromReception.establishment)
                            }
                        });
                        this.fees = result.data.fees

                        this.calculateTva();
                    })
                    .catch(() => {});
                }
            },
            onReceptionListDeleted() {
                this.getArticlesFromReceptions();
            },
            deleteSupplierInvoiceModal(supplierInvoice) {
                this.$refs.dialog.show({
                    type: 'confirm',
                    title: 'Confirmation',
                    message: `Êtes-vous sûr de vouloir supprimer cette facture fournisseur ?`
                }).then(() => {
                    axios
                    .put('supplierInvoice/changeDeleted/' + supplierInvoice.id, {
                        deleted: true
                    }, {
                        toastSuccessMessage: `Modification effectuée`,
                        toastError: false,
                        showPreloader: true
                    })
                    .then(() => {
                        this.emitter.emit('ag-grid-reload');
                    })
                    .catch(() => {});
                })
                .catch(() => {});
            },
            updateQuadraExportDate(event) {
                let selectedMonth = event?.target?.value;
                if (selectedMonth == null || selectedMonth === '') return;

                let date = parse(selectedMonth, 'MM/yyyy', new Date())

                this.quadraStartDate = format(date, "dd/MM/yyyy");
                this.quadraEndDate = lastDayOfMonth(date) && format(lastDayOfMonth(date), "dd/MM/yyyy")

                const startCondition = (this.quadraStartDate !== null ? `?quadraStartDate=${this.quadraStartDate}` : '')
                const endCondition = (this.quadraEndDate !== null ? (startCondition !== '' ? '&' : '?') + `quadraEndDate=${this.quadraEndDate}` : '')
                axios
                    .get(`supplierInvoice/getSupplierInvoiceByPeriod${startCondition}${endCondition}` , {
                        toastError: true,
                        showPreloader: true
                    })
                    .then(response => {
                        this.exportQuadra.supplierInvoices = response.data
                    })
                    .catch(() => {});
            },
            compareTTCAmount() {
                const amount = parseFloat(this.supplierInvoice.tva) + this.articlesFromReceptions.reduce((partialSum, article) => partialSum + (article.quantity * parseFloat(article.buyPrice)), 0) + this.fees?.shippingCosts + this.fees?.packagingCosts;
                this.submitSupplierInvoiceDisabled = (amount && (parseFloat(this.supplierInvoice.invoiceSum) || parseFloat(this.supplierInvoice.invoiceSum) === 0) && amount?.toFixed(2) != parseFloat(this.supplierInvoice.invoiceSum))
                this.calculatedTtcAmount = formatPrice(amount);
                this.ttcAmountDiff = (amount - parseFloat(this.supplierInvoice.invoiceSum))?.toFixed(2);
            }
        }
    }
</script>