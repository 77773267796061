<template>
    <div class="container-action-button" v-if="params.data">
        <BaseButton @click="onDeleteSupplierInvoice" buttonText="" title="Supprimer la facture" :disabled="this.params.data.exported">
            <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'trash']" />
            </template>
        </BaseButton>
        <BaseButton @click="onConsultSupplierInvoice" buttonText="" title="Consulter la facture">
            <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'search']" />
            </template>
        </BaseButton>
    </div>
</template>

<script>
    import BaseButton from '../../components/Base/BaseButton.vue';
    import * as Vue from "vue";
    import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

    export default {
        name: 'ListSupplierInvoicesActionButtonsRenderer',
        components: {
            BaseButton: Vue.shallowRef(BaseButton),
            FontAwesomeIcon: Vue.shallowRef(FontAwesomeIcon)
        },
        methods: {
            onDeleteSupplierInvoice() {
                this.params.context.componentParent.deleteSupplierInvoiceModal(this.params.data);
            },
            onConsultSupplierInvoice() {
                this.params.context.componentParent.displayConsultSupplierInvoiceModal(this.params.data);
            }
        }
    }        
</script>